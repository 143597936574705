.joinSection-wrapper {
  user-select: none;
  margin-bottom: 100px;
  .careers-img-wrapper {
    margin-bottom: -180px;
    .careers-img-one {
      display: flex;
      overflow: hidden;
      justify-content: flex-end;
      position: relative;
      top: -110px;
      z-index: -1;
    }

    .careers-img {
      width: 80%;
      z-index: -1;
    }
    .hr-img {
      width: 40%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
    }
  }
}
.joinSectionCardWrapper {
  background: #30302c;
  box-shadow: inset 0px 25px 15px rgba(0, 0, 0, 0.45);
  border-radius: 32px;
  padding: 30px;

  h3 {
    font-weight: 700;
    font-size: 27px;
    line-height: 35px;
    color: #cdcdcd;
    width: 80%;
    white-space: pre-line;
    height: 100px;
    display: flex;
    align-items: flex-end;
    padding-left: 10px;
  }
  .img-wrapper {
    width: 125px;
    height: 125px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-left: auto;
    margin-top: 20px;
    margin-right: 10px;
    img {
      position: relative;
      z-index: 9;
    }
    .img-wrapper-background {
      background: #30302c;
      box-shadow: inset 0px 25px 25px #000000;
      border-radius: 24px;
      transform: rotate(-45deg);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.join-title {
  font-weight: 700;
  font-size: 36px;
  line-height: 48px;
  text-align: center;
  color: #cdcdcd;
  margin-top: 0;
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
}

.mySwiper {
  padding: 0 15px !important;
}

.homeWrapper {
  margin-right: auto !important;
  text-align: center !important;
}

@media only screen and (max-width: 600px) {
  .joinSection-wrapper {
    .careers-img-wrapper {
      margin-bottom: -60px;
      .careers-img {
        width: 156%;
        z-index: -1;
        right: -50%;
        position: relative;
        top: 0;
      }
      .hr-img {
        width: 68%;
        position: absolute;
        left: 0;
        top: 6%;
        z-index: -1;
      }
    }
  }
}
