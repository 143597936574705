.aboutUs-wrapper {
  padding: 50px 0;
  .about-wrapper-bg {
    background: hsla(0, 0%, 49%, 0.1);
    box-shadow: inset 0px 25px 15px rgba(0, 0, 0, 0.45);
    border-radius: 48px;
    padding: 0 5%;
    position: relative;
    overflow: hidden;
    h2.section-title {
      margin-bottom: 0;
      font-family: Anton, Montserrat;
      line-height: 85px;
      font-weight: 400;
      font-size: 70px;
    }
    p {
      font-family: Inter, sans-serif;
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
      color: #cdcdcd;
      width: 77%;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      position: relative;
      z-index: 1;
      @media (max-width: 430px) {
        font-size: 14px;
      }
    }
  }
  .top-design-1 {
    position: absolute;
    top: 0;
    right: 0;
  }

  .bg-light {
    position: absolute;
    background: rgba(99, 99, 99, 0.2);
    filter: blur(125px);
    border-radius: 400px;
    width: 325.09px;
    height: 326px;
  }

  .light-1 {
    left: 0;
    top: 0;
  }
  .light-2 {
    bottom: 0;
    right: 0;
  }
}

@media (max-width: 430px) {
  .aboutUs-wrapper .about-wrapper-bg p {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 40px;
  }
}
