.mainTitleSection {
  min-height: calc(91vh - 190px);
  padding: 80px 0;
  position: relative;
  display: flex;
  align-items: center;
  padding-right: 6%;

  .page-title {
    color: #cdcdcd;
    font-family: Anton,Montserrat;
    font-size: 8.125rem;
    text-align: right;
    font-weight: 400;
    line-height: 120px;
    margin: 0 0 60px;
    text-transform: uppercase;
    white-space: nowrap;

    span {
      color: #d7b86e;
    }
  }

  .top-bg-img {
    background-image: url("../../../assets/images/top.png");
    background-repeat: no-repeat;
    right: 0;
    width: 55%;
    height: 130vh;
    top: -46%;
    position: absolute;
    z-index: -1;
    background-size: contain;
    background-position: right;
    // transform: scaleX(-1);
  }
  .bottom-home-bg-img {
    background-image: url("../../../assets/images/bottom.png");
    background-repeat: no-repeat;
    left: -17%;
    width: 50%;
    max-width: 800px;
    height: 130vh;
    bottom: -100%;
    position: absolute;
    z-index: -1;
    background-size: contain;
    background-position: right;
  }
}
@media only screen and (max-width: 1600px) {
  .mainTitleSection {
    .top-bg-img {
      height: 119vh;
    }

    .page-title {
      font-size: 124px;
      line-height: 120px;
    }
  }
}

@media only screen and (max-width: 1423px) {
  .mainTitleSection {
    .top-bg-img {
      height: 107vh;
    }
    .page-title {
      font-size: 100px;
      line-height: 100px;
    }
  }
}

@media only screen and (max-width: 1140px) {
  .mainTitleSection {
    .top-bg-img {
      height: 100vh;
    }
    .page-title {
      font-size: 70px;
      line-height: 85px;
    }
  }
}

@media only screen and (max-width: 950px) {
  .mainTitleSection {
    .page-title {
      font-size: 80px;
    }
  }
}
@media only screen and (max-width: 769px) {
  .mainTitleSection {
    .top-bg-img {
      height: 100vh;
      width: 75%;
    }
    .page-title {
      font-size: 75px;
      line-height: 80px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .mainTitleSection {
    .top-bg-img {
      height: 100vh;
      width: 75%;
    }
    .page-title {
      font-size: 70px;
      line-height: 70px;
    }
  }
}
@media only screen and (max-width: 440px) {
  .mainTitleSection {
    min-height: calc(70vh - 190px);
    .top-bg-img {
      height: 75vh;
    }
    .page-title {
      font-size: 55px;
      line-height: 58px;
    }
  }
}

@media only screen and (max-width: 360px) {
  .mainTitleSection {
    .page-title {
      font-size: 44px;
      line-height: 50px;
    }
  }
}
