.team-wrapper {
  padding-bottom: 20px;
  margin-top: 10%;

  .section-title {
    margin-bottom: 0;
  }
}

.team-list-wrapper {
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
  justify-content: center;

  &::-webkit-scrollbar {
    height: 2px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #20201e;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
.container-element {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0 auto;
  justify-items: center;
  padding: 80px;
  width: 1200px;

  .diamond-row {
    display: flex;
    justify-content: center;
    margin-top: -90px;
    flex-wrap: wrap;
    @media (max-width: 768px) {
      margin-top: -17px;
      flex-wrap: nowrap;
    }
  }
}
.team-user-info {
  transform: rotate(-45deg);

  img {
    width: 140px;
    filter: grayscale(1);
    &.active {
      filter: none;
    }
  }
}
.team-bg {
  display: flex;
  width: 100%;
  height: 100%;
  background: #30302c;
  box-shadow: inset 0px 25px 15px rgba(0, 0, 0, 0.45);
  border-radius: 48px;
  align-items: center;
  justify-content: center;
}
.grid-item {
  padding: 0.7rem;
  font-size: 30px;
  text-align: center;
  grid-column: span 3;
  height: 200px;
  width: 200px;
  transform: rotate(45deg);
  margin: 30px;
  display: flex;
}

.user-info-popover {
  background: #111110;
  box-shadow: 0px 4px 50px rgb(237,235,230,0.2);
  border-radius: 32px;
  padding: 20px;
  max-width: 440px;
  width: 100%;
  min-width: 300px;

  .user-name {
    .team-user-name {
      font-family: Anton, Montserrat;
      font-weight: 700;
      font-size: 48px;
      line-height: 64px;
      text-align: center;
      text-transform: capitalize;
      color: #cdcdcd;
      margin: 0 !important;
    }
  }

  .user-position {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #cdcdcd;
    margin: 0 !important;
  }

  .user-info-text {
    font-family: Anton, Montserrat;
    font-weight: 400;
    font-size: 24px;
    line-height: 10px;
    max-width: 80%;
    white-space: pre-wrap;
    text-transform: uppercase;
  }
}
@media only screen and (max-width: 600px) {
  .user-info-popover {
    max-width: 240px;
  }
  .user-info-popover .user-name .team-user-name {
    font-size: 40px;
    line-height: 50px;
  }
  .user-info-popover .user-info-text {
    font-size: 20px;
    line-height: 28px;
  }

  .team-bg {
    border-radius: 32px;
  }

  .grid-item {
    height: 140px !important;
    width: 140px !important;
    grid-column: span 1 !important;
    padding: 0px;
    .team-user-info img {
      width: 80px;
      @media (max-width: 368px) {
        width: 69px;
      }
    }
    @media (max-width: 430px) {
      height: 120px !important;
      width: 120px !important;
    }
    @media (max-width: 368px) {
      height: 100px !important;
      width: 100px !important;
    }
  }
  .team-list-wrapper {
    overflow: hidden;
  }
}
.react-tiny-popover-container {
  z-index: 9999;
}
