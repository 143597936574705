.footer-wrapper {
  // padding-top: 60px;
  margin-top: 7%;
  .footer-link-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (max-width: 430px) {
      margin-top: 0px;
    }

    .navigation-link-wrapper {
      h4 {
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.25em;
        text-transform: uppercase;
        color: #cdcdcd;
        padding-left: 8px;
        font-family: Anton, Montserrat;
      }
      ul {
        list-style: none;
        padding: 0;
      }
      li {
        a {
          button {
            font-family: Inter, sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 10px;
            color: #cdcdcd;
            min-width: auto;
          }
        }
      }
      .social-wrapper {
        min-width: 189px;
      }
    }
  }
  .full-img-logo {
    position: relative;
  }
}
a {
  text-decoration: none;
}
.textDecoration {
  text-decoration: none;
}

@media (max-width: 430px) {
.footer-wrapper {
  margin-top: 5%;
  padding-top: 10px;
}
  .footer-link-wrapper {
    flex-direction: column;
    margin-top: 0px;
  }
  .copyRight {
    padding-bottom: -0px !important;
    margin-bottom: -40px !important;
  }
}