.contact-wrapper {
  background: #30302c;
  box-shadow: inset 0px 25px 15px rgba(0, 0, 0, 0.45);
  border-radius: 32px;
  min-height: 500px;
  max-width: 650px;
  margin: 0 auto;
  position: relative;
  padding: 10%;
  margin-bottom: 40px;

  .contact-bg-effect {
    background: rgba(249, 222, 156, 0.35);
    filter: blur(130px);
    width: 200px;
    height: 200px;
    position: absolute;
    z-index: 0;
  }
  .shad-1 {
    bottom: 10%;
    right: 0;
  }
  .shad-2 {
    top: 10%;
    left: 0;
  }
  .contact-form {
    position: relative;
    z-index: 1;
  }
}
