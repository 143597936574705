@import "./assets/styles/index";
@import url("https://fonts.googleapis.com/css2?family=Antonio:wght@400&family=Inter&display=swap");

body {
  background: #1e1e1e;
  color: #cdcdcd;
  font-family: "Montserrat";
}
.app-background {
  position: relative;
  min-height: 100vh;
  overflow-y: hidden;
}

.app-background::before {
  content: "";
  background-image: url("./assets/images/main.png");
  background-position: top;
  background-repeat: repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.3;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.d-flex {
  display: flex;
}

.page-height {
  min-height: 40vh;
}

.d-block {
  display: block;
}

.selected {
  background-color: chocolate;
}

.cursor-pointer {
  cursor: pointer;
}

.selection-box-info {
  margin-top: 10px;
}

.d-none {
  display: none;
}

.flex-column {
  flex-direction: column;
}

.overflow-unset {
  overflow: unset !important;
}

.ant-modal {
  overflow: hidden;
}

.ant-layout-header {
  background: transparent !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

textarea {
  resize: none;
}

.opacity-1 {
  opacity: 1 !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-aligned-center {
  display: flex;
  align-items: center;
}

.position-absolute {
  position: absolute;
}

.position-fixed {
  position: fixed;
}

.position-relative {
  position: relative;
}

.top-0 {
  top: 0;
}

.height-100 {
  height: 100%;
}

.flex-1 {
  flex: 1;
}
.flex-wrap {
  flex-wrap: wrap;
}

.justify-center {
  justify-content: center;
}

.flex-direction {
  flex-direction: column;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-flex-end {
  justify-content: flex-end;
}

.justify-flex-start {
  justify-content: flex-start;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}
.align-end {
  align-items: flex-end;
}

.white-space_pre-wrap {
  white-space: pre-wrap;
}

.w-full {
  width: 100%;
}

.w-half {
  width: 50%;
}

.w-fit-content {
  width: fit-content !important;
}

.h-full {
  height: 100%;
}

.min-h-unset {
  min-height: unset !important;
}

.min-h-full {
  min-height: 100%;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.relative {
  position: relative;
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: end;
}

.text-no-wrap {
  white-space: nowrap;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.flex-wrap-wrap {
  flex-wrap: wrap;
}

.text-underline {
  text-decoration: underline;
}

.text-line-through {
  text-decoration: line-through;
}

.text-line-through {
  text-decoration: line-through;
}

.opacity-75 {
  opacity: 0.75;
}

.bg-white {
  background-color: #ffffff;
}
