.project-wrapper {
  margin-top: 10%;
  padding: 10px 0 30px;

  .project-card-wrapper {
    background: #30302c;
    box-shadow: inset 0px 25px 15px rgba(0, 0, 0, 0.45);
    border-radius: 32px;
    overflow: hidden;
    position: relative;
    @media (max-width: 430px) {
      background: #000;
    }
    .project-card-space {
      padding: 50px 0 0 50px;
    }
    .logo-height {
      height: 94px;
    }
    .project-logo {
      width: 100%;
      max-width: 240px;
      @media (max-width: 350px) {
        width: 77%;
      }
    }
    .project-img {
      width: 75%;
      position: relative;
      bottom: -5px;
    }
    .rectangle {
      background: #edebe6;
      filter: blur(125px);
      border-radius: 50%;
      width: 200px;
      height: 200px;
      position: absolute;
      bottom: 26%;
      left: 20%;
      z-index: 0;
    }
    .text-wrapper {
      background: linear-gradient(
        180deg,
        rgba(17, 17, 16, 0) 0%,
        rgba(17, 17, 16, 0.897914) 54.34%,
        #111110 77.57%
      );
      padding: 100px 35px 30px 35px;
      position: absolute;
      bottom: -100%;
      width: calc(100% - 70px);
      transition: 0.5s;
      h3 {
        color: #f8f6ff;
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        @media (max-width: 430px) {
          margin: 0px !important;
          line-height: 21px;
        }
        @media (max-width: 375px) {
          font-size: 16px;
          line-height: 18px;
        }
        @media (max-width: 330px) {
          font-size: 14px;
          line-height: 16px;
        }
      }
      .px-25 {
        @media (max-width: 330px) {
          padding-left: 10px !important;
          padding-right: 10px !important;
        }
      }
      .mt-20 {
        @media (max-width: 430px) {
          margin-top: 0px !important;
        }
      }
      @media only screen and (max-width: 600px) {
        padding: 50px 25px 25px 25px;
        width: calc(100% - 50px);
      }
      @media only screen and (max-width: 350px) {
        bottom: -125%;
      }
    }
    &:hover {
      .text-wrapper {
        bottom: 0;
      }
    }
  }
}
