.servicesWrapper {
  padding: 0 0 20px;

  .dark-box-service {
    background: #30302c;
    box-shadow: inset 0px 25px 15px rgba(0, 0, 0, 0.45);
    border-radius: 32px;
    padding: 30px;
    position: relative;
    overflow: hidden;
    white-space: pre-line;
    height: 250px;

    .back {
      padding-top: 30px;
      p {
        font-size: 18px !important;
        margin-top: 20px;
      }
    }
    @media (max-width: 1024px) {
      .back {
        padding-top: 15px !important;
        p {
          font-size: 16px !important;
          margin-top: 10px !important;
        }
      }
    }

    @media (max-width: 768px) {
      .back {
        padding-top: 15px !important;
        p {
          font-size: 14px !important;
          margin-top: 10px !important;
        }
      }
      .text-title {
        font-size: 20px;
      }
    }

    @media (max-width: 430px) {
      .back {
        p {
          font-size: 14px !important;
          margin-top: -10px !important;
          padding-bottom: 10px;
        }
      }
      .text-title {
        font-size: 20px;
      }
    }

    @media (max-width: 440px) {
      min-height: 240px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }

    img {
      max-width: 150px;
    }

    .icon-wrapper {
      background-image: url("../../../assets/images/service/rectangle-service.png");
      background-size: contain;
      background-repeat: no-repeat;
      padding: 50px;
    }
    .text-title {
      font-weight: 700;
      font-size: 24px;
      line-height: 22px;
      color: #cdcdcd;
      white-space: initial;
    }
    .card-title {
      font-family: Anton, Montserrat;
      font-size: 32px;
      font-weight: 400;
      line-height: 40px;
      margin: 10px 0 0;
    }
    .background-card {
      background: #edebe6;
      mix-blend-mode: normal;
      opacity: 0.8;
      filter: blur(86px);
      position: absolute;
      width: 90px;
      height: 90px;
    }
    .card-1 {
      top: 27%;
      right: 30%;
    }
    .card-2 {
      top: 27%;
      left: 30%;
    }
    .card-3 {
      top: 40%;
      right: 20%;
    }
    .card-4 {
      bottom: 20%;
      right: 40%;
    }
    img {
      -webkit-filter: drop-shadow(0 0 8px #fff);
      filter: drop-shadow(0 0 8px white);
    }
  }
}
