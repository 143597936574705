.appBarWrapper {
  box-shadow: none !important;
  padding-top: 40px;

  .linkNavWrapper {
    gap: 20px;
  }
  .logo {
    width: 250px;
  }
}

.textDecoration {
  text-decoration: none;
  button {
    font-family: Anton,Montserrat;
    line-height: 1.75;
    font-weight: 400;
    font-size: 16px;
  }
}
html {
  scroll-behavior: smooth;
}
@media only screen and (max-width: 600px) {
  .appBarWrapper {
    padding-top: 25px;
  }
  .logo {
    width: 200px !important;
  }
}
.color-unset {
  color: unset;
}
